import { IDeserializable } from './deserializable';

export class Image implements IDeserializable{
    src?: string;
    showFirst?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
