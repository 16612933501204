import * as React from 'react'

enum ScreenBreakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1440
}

interface IScreenContext {
    /* breakpoint */
    brk: ScreenBreakpoints,
    mobile: boolean,
    height: number,
    width: number
}

interface IProps {
  children: JSX.Element
}

const ScreenContext = React.createContext<IScreenContext>({} as IScreenContext)

export const ScreenProvider: React.FC<IProps> = ({children}) => {

  const [context, setContext] = React.useState<IScreenContext>({
    brk: ScreenBreakpoints.lg,
    mobile: false,
    height: 0,
    width: 0
  })



  React.useEffect(() => {
    const handleResize = () => {
      var l = window.innerWidth
      var brk = ScreenBreakpoints.xs

      if(l >= ScreenBreakpoints.sm) brk = ScreenBreakpoints.sm
      if(l >= ScreenBreakpoints.md) brk = ScreenBreakpoints.md
      if(l >= ScreenBreakpoints.lg) brk = ScreenBreakpoints.lg
      if(l >= ScreenBreakpoints.xl) brk = ScreenBreakpoints.xl
      if(l >= ScreenBreakpoints.xxl) brk = ScreenBreakpoints.xxl

      setContext({
        brk, 
        mobile: l < ScreenBreakpoints.lg,
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  return <ScreenContext.Provider value={context}>{children}</ScreenContext.Provider>
}

export const useScreenContext = () => {
  return React.useContext(ScreenContext)
}
