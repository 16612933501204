import { Accordion, Col, Container, Row } from 'react-bootstrap'
import './sessao-missao.scss'

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from '../../../themes/colors';
import 'react-loading-skeleton/dist/skeleton.css'

export type MissaoDadosType = {
  titulo: string,
  textoAsHtml: string
}

export const SessaoMissao = ({dados}: {dados?: MissaoDadosType[]}) => {
  return (
    <section id='sessaoMissao'>
      { (dados == undefined || dados.length > 0 ) && 
        <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
          <Container>
            <Row className='linha'>
              <Col className='coluna' xs={12} md={8}>
                { dados ?
                  <AccordionComponent dados={dados}/> :
                  <SkeletonAccordions />
                }
              </Col>
            </Row>
          </Container>
        </SkeletonTheme>
      }
    </section>
  )
}

const SkeletonAccordions = () => {
  return (
    <div className="SkeletonAccordionsContainer">
      {Array(4).fill(null).map((x, i) => 
          <div className="SkeletonAccordionItem" key={`SA${i}`}>
            <Skeleton height={'100%'} width={'100%'} />
          </div>
        )}
    </div>
  )
}

const AccordionComponent = ({dados}: {dados: MissaoDadosType[]}) => {
  return (
    <Accordion /* defaultActiveKey="AI0" */ >
      {dados.map((dado: MissaoDadosType, index: number) => 
        <Accordion.Item eventKey={`AI${index}`} key={`AI${index}`}>
          <Accordion.Header>
            {dado.titulo}

            <i className="bi bi-chevron-up up" style={{ fontSize: '1.5rem' }} ></i>
            <i className="bi bi-chevron-down down" style={{ fontSize: '1.5rem' }} ></i>

          </Accordion.Header>
          <Accordion.Body>
            <div dangerouslySetInnerHTML={{__html: dado.textoAsHtml}}>

            </div>
          </Accordion.Body>
        </Accordion.Item>
    )}
    </Accordion>
  )
}