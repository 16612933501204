import { IDeserializable } from "./deserializable";

export class MenuRec implements IDeserializable {
    id?: number
    name?: string
    description?: string
    html?: string
    isLink: boolean = false
    image?: string
    menuId?: number
    order?: number

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }
}

export type MenuTree = {
    menu: MenuRec
    childrens: MenuTree[]
}