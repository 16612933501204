import { IDeserializable } from "./deserializable";
import { ProdutoServicoType } from "./types/type-produto";

export default class ProdutosModel implements IDeserializable {

  dados?: {
    data: Array<ProdutoServicoType>
    total: number
  };
  erro?: boolean;

  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}