import { Container, Row, Col, Accordion } from "react-bootstrap";
import "./sessao-inspiracoes.scss";
import { useEffect, useLayoutEffect, useState } from "react";
import { gestorReqs } from "../../../servicos/gestor";
import { Estado } from "../../../modelos/types/type-react";
import { SectionDataInformation } from "../../../modelos/section";
import { useScreenContext } from "../../../contexts/screen.context";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../../themes/colors'
import { ImagemViewerZoom } from "../../imagem-viewer-zoom/imagem-viewer-zoom";

class Paginate<T> {
  private elementos: T[] = []

  constructor(elementos: T[]){
    this.elementos = elementos
  }

  public get(pagina: number, tamanhoPagina: number): T[] {
    // let inicio = ( pagina * tamanhoPagina ) - tamanhoPagina
    let inicio = 0
    let fim = (pagina * tamanhoPagina)

    return this.elementos.slice(inicio, fim)
  }

  public getQtdPages(tamanhoPagina: number){
    var qtd = Math.floor(this.elementos.length / tamanhoPagina)

    if((this.elementos.length - (qtd * tamanhoPagina)) > 0)
      qtd += 1

    return qtd
  }
}


const splitarImagens = (colunas: number, imagens: string[]): ({img: string, originalPosition: number})[][] => {
  var imagensSplitadas: ({img: string, originalPosition: number})[][] = []
  var index = 0;

  for (let i = 0; i < colunas; i++) {
    imagensSplitadas.push(new Array())
  }

  while (index < imagens.length) {
    for (let i = 0; (i < colunas) && (index < imagens.length); i++) {
      imagensSplitadas[i].push({
        img: imagens[index],
        originalPosition: index
      })
      index += 1
    }
  }

  return imagensSplitadas
}



export const SessaoInspiracoes = () => {

  const { mobile } = useScreenContext();

  const [estado, setEstado] = useState<Estado>({ carregando: true, erro: false });
  const [dados, setDados] = useState<Paginate<string>[]>([]);
  const [ options, setOptions ] = useState<string[]> ([])

  const [ selectedOption, setSelectedOption] = useState<number>(0)
  const [ page, setPage ] = useState(1)
  const [ maxPage, setMaxPage ] = useState(1)

  const [imagensZoom, setImagensZoom] = useState<string[]>([])

  useEffect(() => {
    gestorReqs._getSections('inspiracoes').then(response => {
      if (!response.error && response.dados) {
        var aux: SectionDataInformation[] = []

        response?.dados?.[0]?.section_informations.forEach(si => {
          if (si.section_data) aux.push(si.section_data)
        });

        var auxOptions = aux?.map(sdi => sdi?.titulo ?? '')
        var paginates: Paginate<string>[] = []

        aux?.forEach(sdi => paginates.push(new Paginate(sdi?.imagensArray?.map(img => img?.src ?? '') ?? [])))

        setOptions([...auxOptions])
        setDados([...paginates]);
        setEstado({carregando: false, erro: false})
        setMaxPage(paginates[selectedOption].getQtdPages(8))
      } else setEstado({ carregando: false, erro: true })
    })
  }, [])

  useLayoutEffect(() => setPage(1), [selectedOption])

  useLayoutEffect(() => {
    if(dados && dados[selectedOption])
      setMaxPage(dados[selectedOption].getQtdPages(8))
  }, [selectedOption])

  const mostrarImagensNoModal = (first: number) => {
    var imagens = [...dados[selectedOption].get(page, 8)]
    var aux = imagens[0]
    imagens[0] = imagens[first]
    imagens[first] = aux

    /* remover esta linha se quiser que o zoomviwer mostre mais do que uma imagem */
    imagens = [imagens[0]]

    setImagensZoom([...imagens])
  }

  if(estado.erro) return <></>
  else return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <ImagemViewerZoom imagens={imagensZoom} indicators={false} controls={!mobile}/>
      <Container id="sessaoInspiracoes">
        <Row>
          { !mobile && !estado.carregando ? 
            <Col xs={12} className="options">
              {
                options.map((option, i) => (
                  <button
                    className={selectedOption === i ? 'selected' : ''}
                    key={i}
                    onClick={() => setSelectedOption(i)}
                  >
                    {option}
                  </button>
                ))
              }
            </Col> : 
            !estado.carregando ? 
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className='titulo'>{options[selectedOption]}</span>

                  <i className="bi bi-chevron-up up" style={{ fontSize: '1.5rem' }} ></i>
                  <i className="bi bi-chevron-down down" style={{ fontSize: '1.5rem' }} ></i>
                  {/* <ChevronUp className="up"></ChevronUp>
                  <ChevronDown className="down"></ChevronDown> */}

                </Accordion.Header>
                <Accordion.Body>
                  {
                    options.map((option, i) => (
                      <button 
                        key={i}
                        onClick={() => setSelectedOption(i) }
                      >
                        {option}
                      </button>
                    ))
                  }
                </Accordion.Body> 
              </Accordion.Item>
            </Accordion> : 
            <SkeletonOptions />
          }
        </Row>
        { dados[selectedOption] && !estado.carregando  && 
          <Row className='imagens'>
            {
              splitarImagens(mobile ? 2 : 4, dados[selectedOption].get(page, 8)).map((imagens, i) => (
                <Col xs={mobile ? 6 : 3} key={i}>
                  {
                    imagens.map((imagem, idx) => (
                      <div className='imagem' key={idx} onClick={() => mostrarImagensNoModal(imagem.originalPosition)}>
                        <img src={imagem.img} alt="imagem inspiração" />
                      </div>
                    ))
                  }
                </Col>
              ))
            }
          </Row> ||
          <SkeletonImagens />
        }
      
      { page < maxPage && !estado.carregando && 
        <Row>
          <Col xs={12} className='carregarMais'>
            <button onClick={() => setPage(page+1)}>
              CARREGAR MAIS
            </button>
          </Col>
        </Row>
      }
      
      </Container>
    </SkeletonTheme>
  );
};


// -------------------- Skeletons ----------------------

const SkeletonImagens = () => {
  const alturas = [
    [145, 245, 145, 245], 
    [245, 145, 245, 145]
  ]
  const { mobile } = useScreenContext()
  
  return (
    <Row className="skeletonImagens">
      {
        Array(mobile ? 2 : 4).fill(null).map((x, xi) => (
          <Col xs={6} lg={3} key={`X${xi}`}>
            {
              alturas[xi % 2].map((y, yi) => (
                <div className='imgSkeleton' style={{"height": `${y}px`}} key={`Y${xi}-${yi}`}>
                  <Skeleton width={'100%'} height={'100%'} />
                </div>
              ))
            }
          </Col>
        ))
      }
    </Row>
  )
}

const SkeletonOptions = () => {
  return (
    <div className='skeletonOptions'>
      <div>
        <Skeleton width={'100%'} height={'100%'} />
      </div>
    </div>
  )
}