import React, { useEffect } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { gestorReqs } from '../../servicos/gestor'
import { toTop } from '../../utilidades/manipuladores-html'
import { useTitle } from '../../utilidades/tittle'
import './pagina-produtos.scss'

export const PaginaProdutos = () => {

  useTitle("Produtos - Real Metais");
  
  return (
    <section id='paginaProdutos'>
      <SessaoHeader variant='opaque' />
      <div className="ancora"></div>
      <Outlet />
    </section>
  )
}