import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { COLORS } from '../../themes/colors';
import './banner.scss';
import parse from "html-react-parser";

export type BannerVariant = 'normal' | 'interna' | 'contido'

export type ThumbnailBanner = {
  img: string,
  description?: string,
  title?: string,
  breadcrumbs?: ({ name: string, url: string })[]
}

export const Banner = ({ variant, thumbnails, description }: { variant: BannerVariant, thumbnails: ThumbnailBanner[], description?: string }) => {
  return (
    thumbnails.at(0) ?
      <div style={{position:'relative'}} >
        <Carousel controls={false} indicators={false}>
          {sections(variant, thumbnails)}
        </ Carousel>
        {description && <h1 className={'titulo-banner'} >{parse(description)}</h1>}
        {/* <h1 className={'titulo-banner'} >Com a linha completa de torneiras<br/>e acabamentos em Metal e ABS,<br/>a reforma e construção da casa<br/>dos seus sonhos, agora, é REAL!</h1> */}
      </div>
      :
      <section id="banner" className='carregando'>
        <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
          {variant == 'contido' ?
            <Container>
              <Skeleton className={`bannerLoading ${variant}`} />
            </Container> :
            <Skeleton className={`bannerLoading ${variant}`} />
          }
        </SkeletonTheme>
      </section>
  )
}

const sections = (variant: BannerVariant, thumbnails: ThumbnailBanner[]) => {
  return thumbnails.map((thumbnail, i) =>
    <Carousel.Item key={i}>
      <section id='banner' className={`${variant} ${variant == 'contido' ? 'container' : ''}`}>
        <img className="imagem" src={thumbnail.img} alt="imagem" loading='lazy' />
        {/* <img className="sombra" src={sombra} alt="sombra" /> */}
        <div className="caixa">
          {variant != 'normal' &&
            <div className={`detalhes ${variant == 'interna' ? 'container' : ''}`}>
              <span className="title">{thumbnail.title}</span>
              <Breadcrumbs breadcrumbs={thumbnail.breadcrumbs ? thumbnail.breadcrumbs : []} />
              <span className="desc">{thumbnail.description}</span>
            </div>
          }
        </div>
      </section>
    </Carousel.Item>
  )
}

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: ({ name: string, url: string })[] }) => {
  return (
    <div className="breadcrumbs">
      {
        breadcrumbs.map((breadcrumb, i) => {
          return breadcrumb.url != '#' ? (
            <span key={i}>
              <Link title={breadcrumb.name} to={breadcrumb.url} reloadDocument>{breadcrumb.name}</Link>
              {
                i != breadcrumbs.length - 1 &&
                <span className='separador'>{'>'}</span>
              }
            </span>
          ) : (
            <span key={i}>{breadcrumb.name}</span>
          )
        }
      )
      }
    </div>
  )
}