import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './listagem-blogs.scss'

// Import Swiper React components and styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
// https://swiperjs.com/swiper-api

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { COLORS } from '../../themes/colors'

export type SecaoListagemBlogsType = {
  subtitulo: string, 
  titulo: string, 
  texto: string,
  imgSrc: string,
  link: string
}

export type ListagemBlogsVariant = 'resumo' | 'normal' | 'relacionados'

export const ListagemBlogs = ({ variant, dados }: { variant: ListagemBlogsVariant, dados: SecaoListagemBlogsType[] | undefined }) => {

  if (variant === 'resumo')
    return (dados && <ListagemBlogsResumo dados={dados}/> || <></>)
  
  else if(variant === 'normal')
    return (<ListagemBlogsNormal dados={dados}/>)

  return (
    <section id='listagemBlogs'>
      Listagem Blogs Works - {variant}
    </section>
  )
}

const Item = ({dado}:{dado: SecaoListagemBlogsType}) => {
  return (<div className='item'>
      <div className='img'>
        <img src="https://drive.google.com/uc?export=view&id=1JM17KI_YXgF1NaBdbOBzyH8Ywi1z98_T" alt="" />
      </div>
    <div className='body'>
      <div className="content">
        <span className='title1'>{dado.subtitulo}</span>
        <span className='title2'>{dado.titulo}</span>
        <span className='text'>adipiscing elit. Praesent a sed ac lorem. Urna placerat habitant est volutpat amet volutpat, facilisi. In vehicula egestas proin egestas eu id fusce. Imperdiet nisl enim sed aliquet.</span>
        <div className='esconder'>
          <Link to={dado.link} className='link' title={dado.titulo}>
            <span>Ler blog</span>
          </Link>
        </div>
      </div>
    </div>
  </div>)
}

const ListagemBlogsNormal = ({dados}:{dados: SecaoListagemBlogsType[] | undefined}) => {
  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <section id='listagemBlogs' className='normal'>
        { dados &&
          <Row>
            { dados.length > 0 && dados.map((dado, i) => 
                <Col xs={12} lg={6} key={`CILBN-${i}`}>
                  <Item  dado={dado} />
                </Col>
              ) 
              || <Col xs={12} className='notFoundBlogs'><span>Nenhum blog existente para esta categoria !</span></Col>
            }
          </Row>
          || <Row>{Array(8).fill(null).map((v, i) => <Col xs={12} lg={6} key={`CILBNS-${i}`}><SkeletonItem /></Col>)}</Row>
        }
      </section>
    </SkeletonTheme>
  )
}

const ListagemBlogsResumo = ({dados}:{dados: SecaoListagemBlogsType[]}) => {
  const [size, setSize] = React.useState([0, 0]);
  const [slidesQtd, setSlidesQtd] = React.useState<number>(3);

  React.useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);

    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  React.useEffect(() => {
    if(size[0] < 991.98)
      setSlidesQtd(1)
    else
      setSlidesQtd(3)
  }, [size])

  return (
    <section id='listagemBlogs' className='resumo'>
      <Container>
        <Row>
          <Col xs={12}>
            <Swiper
              spaceBetween={50}
              slidesPerView={slidesQtd}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {dados.map((dado, i) => <SwiperSlide key={`SLBR${i}`}><Item dado={dado} /></SwiperSlide>)}
              {size[0] > 991.98 && <SwiperSlide className='last-end'></SwiperSlide>}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  )
}





// ---------- Skeletons ----------------


const SkeletonItem = () => {
  return (
    <div className='skeletonItemLB'>
      <Skeleton width={'100%'} height={'100%'} />
    </div>
  )
}
