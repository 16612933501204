import { Col, Container, Row } from "react-bootstrap";
import "./sessao-copyright.scss";

export const SessaoCopyright = () => {
  return (
    <section id="sessaoCopyright">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="linha" />
          </Col>
          <Col lg={6} className="direitos">
            <p>
              © {new Date().getFullYear()} | Real Metais Sanitários. Todos os
              direitos reservados.
            </p>
          </Col>
          <Col lg={6} className="tytotech">
            <a
              href="https://tytotech.com.br/"
              title="Desenvolvido por Tytotech"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://tytotech.com.br/web-files/imagens/logos/logo-tytotech.svg"
                alt="Tytotech"
                width="85px"
                height="22px"
              />
            </a>
            <span> {`${process.env.REACT_APP_VERSION}`}</span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
