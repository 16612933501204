import { Col, Container, Row } from "react-bootstrap";

import "./sessao-maps.scss";

export const SessaoMaps = () => {
  return (
    <section id="sessaoMaps">
      <Container fluid>
        <Row>
          <Col lg={12} className="itemMaps">
            <iframe
              className="maps"
              title="maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14698.902897617105!2d-53.1490746!3d-22.9234852!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4418c6f5c23e5030!2sReal%20Metais!5e0!3m2!1spt-BR!2sbr!4v1661975864371!5m2!1spt-BR!2sbr"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
