export type ContatoType = {
  email?: string;
  nome?: string;
  cidade?: string;
  uf?: string;
  assunto?: string;
  mensagem?: string;
  telefone?: string;
  captcha?: string;
  pais?: string;
}

export type ContatoTrabalheConoscoType = {
  nome: string,
  email: string,
  telefone: string,
  dataNascimento: string,
  areaInteresse: string,
  file: File,
  captcha: string
}

export enum ContatoStatus{
  OK = 200,
  ERROR = 400
}