import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { gestorReqs } from '../../servicos/gestor'
import { FuncoesAuxiliares } from '../../utilidades/funcoes-auxiliares'
import { useTitle } from '../../utilidades/tittle'
import { PaginaSobre } from '../pagina-sobre/pagina-sobre'
import './pagina-blogs.scss'

export const PaginaBlogs = () => {
    const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])

    const caminho = useLocation().pathname;
    const local = useLocation().pathname.split("/");

    useTitle("Blogs - Real Metais");


    React.useEffect(() => {
        gestorReqs.getBannerInterno('blog').then(resposta => {

            const breadcrumbs = [
                {
                    name: 'home',
                    url: '/'
                }, {
                    name: 'Blog',
                    url: '/blog'
                }]

            if (local[3]) breadcrumbs.push({
                name: FuncoesAuxiliares.formatCaminhoTitulo(local[3]??""),
                url: caminho
            });

            setThumbnailsBanner([{
                title: 'blog',
                img: resposta.dados?.at(0)?.imagensArray.at(0)?.src ?? "",
                breadcrumbs: breadcrumbs
            }]);
        })
    }, [caminho, local])

    return (
        <section id='paginaBlogs'>
            <SessaoHeader variant='transparent' acessoRapido/>
            <Banner thumbnails={thumbnailsBanner} variant='interna' />
            <Outlet />
        </section>
    )
}
