import React from 'react'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { SessaoInspiracoes } from '../../componentes/sessoes/sessao-inspiracoes/sessao-inspiracoes'
import { gestorReqs } from '../../servicos/gestor'
import { toTop } from '../../utilidades/manipuladores-html'
import { useTitle } from '../../utilidades/tittle'
import ScrollToTop from '../../utilidades/to-top'
import './pagina-inspiracoes.scss'

export const PaginaInspiracoes = () => {
  const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])
  
  useTitle("Inspirações - Real Metais");

  React.useEffect(() => {
    gestorReqs.getBannerInterno('inspiracoes').then(resposta => {
      setThumbnailsBanner([{
        title: 'inspirações',
        img: resposta.dados?.at(0)?.imagensArray.at(0)?.src??"",
        breadcrumbs: [
          {
            name: 'home',
            url: '/'
          }, {
            name: 'inspirações',
            url: '/inspiracoes'
          }],
      }]);
    })
  }, [])


  return (
    <section id='paginaInspiracoes'>
      <ScrollToTop />
      <SessaoHeader variant='transparent' acessoRapido/>
      <Banner variant='interna' thumbnails={thumbnailsBanner} />
      <SessaoInspiracoes />
    </section>
  )
}