function formatCaminhoTitulo(caminho: string) {
  return caminho.replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letra: string) => letra.toUpperCase());
}

function verificaStringNumber(string: string) {
  return !isNaN(parseInt(string));
}

export function toUrlAmigavelConverter(url: string): string {
  return url.toLowerCase()
    .replace(/[áàãâä]/g, 'a')
    .replace(/[éèẽêë]/g, 'e')
    .replace(/[íìîĩï]/g, 'i')
    .replace(/[óòõôö]/g, 'o')
    .replace(/[úùũûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/['"!@#$%¨&*()-+:<>=|\\/ºª°?]/g, '')
    .replace(/ /g, '-')
}

function urlNormalize(caminho: string) {
  return caminho.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(" ", "-");
}

const validarTelefone = (numero?: string) => {
  const regex = new RegExp('^\\(?[0-9]{2}\\)? ?9?[0-9]{4}-?[0-9]{4}$');

  if (regex.test(numero as string)) return true;

  return false;
}

const validarEmail = (email?: string) => {
  const regex = new RegExp('^[^@\\s]+@[a-zA-Z0-9]+(\\.[a-zA-Z]+)+$');
  if (regex.test(email?.toLowerCase() as string)) return true;
  return false;
}

const validarDataNascimento = (date?: string) => {
  const regex = new RegExp(/(\d{2})\/(\d{2})\/(\d{2})/)

  if (regex.test(date as string)) {

    const dates = date?.split('/')

    if (
      dates && (dates[0] && Number(dates[0]) > 0 && Number(dates[0]) <= 31) &&
      (dates[1] && Number(dates[1]) > 0 && Number(dates[1]) <= 12) &&
      (dates[2] && Number(dates[2]) > 1900 && Number(dates[2]) <= new Date().getFullYear())
    ) return true
    else return false

  }
  return false
}

const mascararTelefone = (tel: string) => {
  var telMasked = tel.replace(/[^\d]/g, '')
  if (telMasked.length <= 9)
    telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
  else
    telMasked = telMasked.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

  if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
  return telMasked;
}

const mascararDataNascimento = (date: string) => {
  var dateMasked = date.replace(/[^\d]/g, '')
  if (dateMasked.length > 8) dateMasked = dateMasked.substring(0, 8)
  if (dateMasked.length < 5) dateMasked = dateMasked.replace(/(\d{2})(\d)/, "$1/$2")
  else dateMasked = dateMasked.replace(/(\d{2})(\d{2})(\d)/, "$1/$2/$3")
  return dateMasked
}

const dataParaEscrita = (data?: string) => {

  if(!data)return;

  const meses = ["jan", "fev", "mar", "abr", "maio", "jun", "jul", "ago", "set", "out", "nov", "dez"];

  const particao = data.split("/");

  return `${particao.at(0)} de ${meses[Number(particao.at(1))]} de ${particao.at(2)}`;

}

export const FuncoesAuxiliares = {
  validarTelefone,
  validarEmail,
  validarDataNascimento,
  mascararTelefone,
  mascararDataNascimento,
  formatCaminhoTitulo,
  verificaStringNumber,
  toUrlAmigavelConverter,
  urlNormalize,
  dataParaEscrita 
};