import { Col, Container, Row } from 'react-bootstrap'
import './sessao-acesso-rapido.scss'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Skeleton from 'react-loading-skeleton';

type Input = {
  telefone: string
  email: string
  portal: string
  facebook: string
  youtube: string
  instagram: string
}

type Estado = {
  carregando: boolean,
  erro: boolean,
}

export const  SessaoAcessoRapido = ({ input, estado, isMobile }: { input: Input, estado: Estado, isMobile: boolean }) => {

  return (
    <ThemeProvider>
      <section id='sessaoAcessoRapido'>
        {
          estado.erro ? <></> : estado.carregando ? <SkeletonAcessoRapido /> :
            <Container>
              <Row className='box'>
                <Col xs={isMobile ? 12 : 8} className="leftBox">
                  <div className='option iconBox'>
                    <a href={"tel:" + input?.telefone} title={input?.telefone}>
                      {/* <Telephone className='icon' /> */}
                      <i className="bi bi-telephone icon" style={{ color: "white" }} ></i> {input?.telefone}
                    </a>
                  </div>
                  <div className='option iconBox'>
                    <a href={"mailto:" + input?.email} title={input?.email}>
                      {/* <Envelope className='icon' /> */}
                      <i className="bi bi-envelope icon" style={{ color: "white" }} ></i>  {input?.email}
                    </a>
                  </div>
                  <div className='option iconBox'>
                    <a href={input?.portal} className='option' target='blank' title="Portal do Representante">
                      {/* <Person className='icon' /> */}
                      <i className="bi bi-person icon" style={{ color: "white" }} ></i> Portal do Representante
                    </a>
                  </div>
                </Col>
                {isMobile == false && <Col xs={4} className="rightBox">
                  <a href={input?.instagram} title="Instagram" target='blank'>
                    <i className="bi bi-instagram icon" style={{ color: "white" }} ></i> 
                    {/* <Instagram className='icon' /> */}
                  </a>
                  <a href={input?.facebook} title="Facebook" target='blank'>
                    {/* <Facebook className='icon' /> */}
                    <i className="bi bi-facebook icon" style={{ color: "white" }} ></i> 
                  </a>
                  <a href={input?.youtube} title="YouTube" target='blank'>
                    <i className="bi bi-youtube icon" style={{ color: "white" }} ></i> 
                    {/* <Youtube className='icon' /> */}
                  </a>
                </Col>}
              </Row>

            </Container>
        }
      </section>

    </ThemeProvider>
  )
}


// ----------- skeletons --------------

const SkeletonAcessoRapido = () => {
  return (
    <Container>
      <div className='skeletonAcessoRapido'>
        <Skeleton width={'100%'} height={'100%'} />
      </div>
    </Container>
  )
}