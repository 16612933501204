import "./detalhes-produto.scss";
import { COLORS } from "../../themes/colors";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  Accordion,
  Carousel,
  CarouselItem,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import React, { useEffect } from "react";

import garantia from "../../assets/imagens/garantia.png";
import sucesso from "../../assets/imagens/sucesso.png";
import download from "../../assets/imagens/downloadIcon.png";

import parse from "html-react-parser";
import { ImagemViewerZoom } from "../imagem-viewer-zoom/imagem-viewer-zoom";
import {
  removerProdutoDosFavoritos,
  setarProdutoComoFavorito,
  verificarSeProdutoEhFavorito,
} from "../../utilidades/manipuladores-local-storage";
import { useParams } from "react-router-dom";
import { gestorReqs } from "../../servicos/gestor";

import { ImagemType } from "../../modelos/types/type-imagem";
import { ArquivoType } from "../../modelos/types/type-arquivo";
import { extrairCodigoDeProduto, toTop } from "../../utilidades/manipuladores-html";
import { useTitle } from "../../utilidades/tittle";
import { GarantiaProduto } from "../garantia-produto/garantia-produto";

type DetalhesProdutoType = {
  imagens: string[];
  titulo: string;
  textoHtml: string;
  garantiaHtml: string;
  arquivos: { nome: string; link: string }[];
  especificacoesHtml: string;
};

type Estado = {
  carregando: boolean;
  erro: boolean;
};

const splitarImagens = (qtdPorLinha: number, imagens: string[]): string[][] => {
  var imagensSplitadas: string[][] = [];
  var coluna = 0;

  while (1) {
    var array: string[] = [];

    for (let cont of Array(qtdPorLinha).fill(null)) {
      if (coluna >= imagens.length) break;
      array.push(imagens[coluna]);
      coluna += 1;
    }

    imagensSplitadas.push(array);

    if (coluna >= imagens.length) break;
  }

  return imagensSplitadas;
};

export const DetalhesProduto = () => {
  const { produtoId } = useParams();
  const [destaque, setDestaque] = React.useState<number>(0);
  const [estado, setEstado] = React.useState<Estado>({
    carregando: true,
    erro: false,
  });
  const [dados, setDados] = React.useState<DetalhesProdutoType>();

  const [imagensZoom, setImagensZoom] = React.useState<string[]>([]);

  const [ehFavorito, setEhFavorito] = React.useState<boolean>(
    verificarSeProdutoEhFavorito(Number(produtoId))
  );

  useEffect(() => {
    setEstado({ carregando: true, erro: false });
    toTop();
    gestorReqs.getProdutoById(Number(produtoId)).then((response) => {
      if (!response.error && response.dados) {
        var json: DetalhesProdutoType = {
          imagens: imagensArrToStringArr(response.dados.imagensArray),
          titulo: response.dados.title,
          textoHtml: response.dados.description,
          arquivos: response.dados.arquivosArray.map(
            arquivoTypeToArquivoDetalhes
          ),
          especificacoesHtml: response.dados.specification,
          garantiaHtml: response.dados.warranty,
        };
        setDados(json);
        setEhFavorito(verificarSeProdutoEhFavorito(Number(produtoId)));
        setEstado({ carregando: false, erro: false });
      } else setEstado({ carregando: false, erro: false });
    });
  }, [produtoId]);

  const imagensArrToStringArr = (imagens: ImagemType[]): string[] => {
    imagens.forEach((img, idx) => {
      if (img.showFirst) {
        var aux = imagens[0];
        imagens[0] = img;
        imagens[idx] = aux;
      }
    });

    return imagens.map((img) => img.src);
  };

  const arquivoTypeToArquivoDetalhes = (
    arquivo: ArquivoType
  ): { nome: string; link: string } => {
    var splitado: string[] = arquivo.src.split("/");

    return {
      nome: splitado[splitado.length - 1],
      link: arquivo.src,
    };
  };

  const setarFavorito = (favoritar: boolean) => {
    if (favoritar) setarProdutoComoFavorito(Number(produtoId));
    else removerProdutoDosFavoritos(Number(produtoId));

    setEhFavorito(verificarSeProdutoEhFavorito(Number(produtoId)));
  };

  const mostrarImagensNoModal = (first: number) => {
    var imagens = [...dados!.imagens];
    var aux = dados!.imagens[0];
    imagens[0] = imagens[first];
    imagens[first] = aux;

    setImagensZoom([...imagens]);
  };

  useTitle(dados?.titulo ?? "Produto");

  return estado.erro ? (
    <></>
  ) : (
    <SkeletonTheme
      baseColor={COLORS.skeletonBaseColor}
      highlightColor={COLORS.skeletonHighlightColor}
    >
      {estado.erro || !dados ? (
        <></>
      ) : (
        <section id="detalhesProduto">
          <Container>
            <ImagemViewerZoom imagens={imagensZoom} />
            <Row className="rowDetalhes">
              {estado.carregando ? (
                <SkeletonDestaque />
              ) : (
                <Col xs={12} lg={6} className="boxImagens">
                  <div
                    className="destaque"
                    onClick={() => mostrarImagensNoModal(destaque)}
                  >
                    <img src={dados!.imagens[destaque]} alt="imagem produto" />
                  </div>

                  <div className="carouselBox">
                    {dados!.imagens.length > 4 && (
                      // <ChevronLeft className="iconBtn iconPrev" />
                      <i className={`bi bi-chevron-left iconBtn iconPrev`} />
                    )}
                    {dados!.imagens.length > 4 && (
                      // <ChevronRight className="iconBtn iconNext" />
                      <i className={`bi bi-chevron-right iconBtn iconNext`} />
                    )}

                    <Carousel
                      interval={null}
                      controls={dados!.imagens.length > 4 ?? false}
                    >
                      {splitarImagens(4, dados!.imagens).map(
                        (linha: string[], idx: number) => (
                          <CarouselItem key={idx}>
                            <Container fluid>
                              <Row>
                                {" "}
                                {linha.map((imagem: string, i: number) => (
                                  <Col
                                    xs={3}
                                    className="miniImagem"
                                    key={i}
                                    onClick={() => setDestaque(4 * idx + i)}
                                  >
                                    <img src={imagem} alt="imagem produto" />
                                  </Col>
                                ))}
                              </Row>
                            </Container>
                          </CarouselItem>
                        )
                      )}
                    </Carousel>
                  </div>
                </Col>
              )}
              {estado.carregando ? (
                <SkeletonDetalhes />
              ) : (
                <Col xs={12} lg={6} className="boxDetalhes">
                  <div className="boxTitulo">
                    <span className="titulo">{dados!.titulo}</span>

                    {
                      dados && !!dados.especificacoesHtml && !!extrairCodigoDeProduto(dados.especificacoesHtml) && 
                      <>
                        <br />
                        <span className="codigoTitulo">Código: {extrairCodigoDeProduto(dados.especificacoesHtml)}</span>
                      </>
                    }

                    {!ehFavorito && (
                      <div
                        className="favoritoBotao"
                        onClick={() => setarFavorito(true)}
                      >
                        {/* <Heart className="favorito" /> */}
                        <i className={`bi bi-heart favorito`} />
                      </div>
                    )}

                    {ehFavorito && (
                      <div
                        className="favoritoBotao"
                        onClick={() => setarFavorito(false)}
                      >
                        {/* <HeartFill
                          className="favorito"
                          onClick={() => setarFavorito(false)}
                        /> */}
                        <i className={`bi bi-heart-fill favorito`} onClick={() => setarFavorito(false)} />
                      </div>
                    )}
                  </div>
                  { !!dados.textoHtml && !!dados.textoHtml.trim() && <div className="descricao">{parse(dados.textoHtml)}</div> }
                </Col>
              )}
              {estado.carregando ? (
                <></>
              ) : (
                <Col xs={12}>
                  <div className='ancora-home' id='ancora-home-2'></div>
                  <Accordion className="accordions">
                    {dados?.especificacoesHtml ? (
                      <Accordion.Item
                        eventKey="acc-especificaes"
                        key="acc-especificaes"
                      >
                        <Accordion.Header>
                          <div className="tituloEIcone">
                            {/* <ColumnsGap className="iconeTitulo" /> */}
                            <i className={`bi bi-columns-gap iconeTitulo`} />
                            Especificações
                          </div>
                          <i className={`bi bi-chevron-up up`} />
                          <i className={`bi bi-chevron-down down`} />
                          {/* <ChevronUp className="up"></ChevronUp>
                          <ChevronDown className="down"></ChevronDown> */}
                        </Accordion.Header>
                        <Accordion.Body className="arquivoAccordionBody">
                          <div className="boxEspecificacoes">
                            <div className="especificacoes">
                              {parse(dados!.especificacoesHtml)}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                  </Accordion>
                  <Accordion className="accordions">
                    <Accordion.Item
                      eventKey="acc-descricao"
                      key="acc-descricao"
                    >
                      <Accordion.Header>
                        <div className="tituloEIcone">
                          <div className="iconeTitulo">
                            <img src={garantia} />
                            <img src={sucesso} className="sucessoImg" />
                          </div>
                          Garantia
                        </div>
                        <i className={`bi bi-chevron-up up`} />
                        <i className={`bi bi-chevron-down down`} />
                        {/* <ChevronUp className="up"></ChevronUp>
                        <ChevronDown className="down"></ChevronDown> */}
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* {parse(dados!.garantiaHtml)} */}
                        <GarantiaProduto />
                      </Accordion.Body>
                    </Accordion.Item>

                    {dados?.arquivos?.at(0) ? (
                      <Accordion.Item eventKey="acc-arquivo" key="acc-arquivos">
                        <Accordion.Header>
                          <div className="tituloEIcone">
                            <i className={`bi bi-download iconeTitulo`} />
                            {/* <Download className="iconeTitulo" /> */}
                            Arquivos Para Baixar
                          </div>
                          <i className={`bi bi-chevron-up up`} />
                          <i className={`bi bi-chevron-down down`} />
                          {/* <ChevronUp className="up"></ChevronUp>
                          <ChevronDown className="down"></ChevronDown> */}
                        </Accordion.Header>
                        <Accordion.Body className="arquivoAccordionBody">
                          {dados!.arquivos.map((arquivo, idx) => (
                            <a href={arquivo.link} target="_blank" key={idx} rel="noreferrer">
                              <div className="iconeLink">
                                <img src={download} />
                                <img src={sucesso} className="sucessoImg" />
                              </div>
                              {arquivo.nome}
                            </a>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                  </Accordion>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      )}
    </SkeletonTheme>
  );
};

const SkeletonDestaque = () => {
  return (
    <Col xs={12} lg={6} className="skeletonDestaque">
      <Skeleton width={"100%"} height={"100%"} />
    </Col>
  );
};

const SkeletonDetalhes = () => {
  return (
    <Col xs={12} lg={6} className="skeletonDetalhes">
      <div className="titulo">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
      <div className="descricao">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
      <div className="accordion">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
      <div className="accordion">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
    </Col>
  );
};

const SkeletonEspecificacoes = () => {
  return (
    <div className="skeletonEspecificacoes">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};
