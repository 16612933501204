import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { gestorReqs } from "../../../servicos/gestor";
import "./sessao-informacoes-contato.scss";

import { FiPhoneCall } from 'react-icons/fi'; 

export const SessaoInformacoesContato = () => {
  type SessaoInformacoesContatoType = {
    loading: boolean;
    erroAoCarregar: boolean;
    dados: {
      telefones?: Array<{ phone?: string }>;
      whatsapp?: string;
      email?: string;
      endereco?: string;
      horario?: string;
    };
  };

  const [estado, setEstado] = useState<SessaoInformacoesContatoType>({
    loading: true,
    erroAoCarregar: false,
    dados: {},
  });

  useEffect(() => {
    gestorReqs.getInformacoes().then((resposta) => {
      estado.dados = {
        telefones: resposta.phones,
        whatsapp: resposta.whatsapp,
        email: resposta.email,
        endereco: `${resposta.address} - ${resposta.neighborhood}, ${resposta.number} - ${resposta.city} - ${resposta.state} - ${resposta.cep}`,
        horario: resposta.working_hours,
      };
      estado.loading = false;
      setEstado({ ...estado });
    });
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SessaoInformacoesContato">
      <Container>
        {estado.loading ? (
          <SkeletonTheme>
            <Row className="conatinerLoading">
              <Col lg={2}>
                <Skeleton className="infoLoading" />
              </Col>
              <Col lg={2}>
                <Skeleton className="infoLoading" />
              </Col>
              <Col lg={3}>
                <Skeleton className="infoLoading" />
              </Col>
              <Col lg={3}>
                <Skeleton className="infoLoading" />
              </Col>
              <Col lg={2}>
                <Skeleton className="infoLoading" />
              </Col>
            </Row>
          </SkeletonTheme>
        ) : (
          <Row>
            {estado.dados.telefones?.at(1) && (
              <Col lg={2} sm={12} className="colContato">
                <div className="itemContatoInfo">
                  <div className="iconesImg">
                  <span className="icon-text">SAC: </span>
                  <FiPhoneCall className="icon phone" />
                  {/* <Icon.Whatsapp className="icon" /> */}
                  </div>
                  <div className="textoInfo">
                    {" "}
                    <span className = "link">
                     { estado.dados.telefones?.at(1)?.phone }
                    </span>
                  </div>
                </div>
              </Col>
            )  || <></>}

            {estado.dados.whatsapp ? (
              <Col lg={2} sm={12} className="colContato">
                <div className="itemContatoInfo">
                  <div className="iconesImg">
                    <i className="bi bi-telephone icon phone" style={{ fontSize: '1.5rem' }} ></i>
                    <i className="bi bi-whatsapp icon" style={{ fontSize: '1.5rem' }} ></i>
                    {/* <Telephone className="icon phone" />
                    <Whatsapp className="icon" /> */}
                  </div>
                  <div className="textoInfo">
                    {" "}
                    <a className = "link"
                      href={`https://api.whatsapp.com/send/?phone=${estado.dados.whatsapp.replace(
                        /\D+/g,
                        ""
                      )}&text&type=phone_number&app_absent=0`}
                    >
                      <p>{estado.dados.whatsapp}</p>
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}

            {estado.dados.email ? (
              <Col lg={3} sm={12} className="colContato">
                <div className="itemContatoInfo">
                  <div className="iconesImg">
                    {/* <Envelope className="icon" /> */}
                    <i className="bi bi-envelope icon" style={{ fontSize: '1.5rem' }} ></i>
                  </div>
                  <div className="textoInfo">
                    <a className = "link" href={"mailto:" + estado.dados.email}>
                      <p>{estado.dados.email}</p>
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}

            {estado.dados.endereco ? (
              <Col lg={3} sm={12} className="colContato">
                <div className="itemContatoInfo">
                  <div className="iconesImg">
                    {/* <GeoAlt className="icon" /> */}
                    <i className="bi bi-geoalt icon" style={{ fontSize: '1.5rem' }} ></i>
                  </div>
                  <div className="textoInfo">
                    <p>{estado.dados.endereco ?? ""}</p>
                  </div>
                </div>
              </Col>
            ) : null}

            {estado.dados.horario ? (
              <Col lg={2} sm={12} className="colContato">
                <div className="itemContatoInfo">
                  <div className="iconesImg">
                    {/* <Clock className="icon" /> */}
                    <i className="bi bi-clock icon" style={{ fontSize: '1.5rem' }} ></i>
                  </div>
                  <div className="textoInfo">
                    {HTMLReactParser(estado.dados.horario ?? "")}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        )}
      </Container>
    </section>
  );
};
