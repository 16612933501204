export class Paginacao<T> {

  constructor(pagina: number, itensPorPagina: number, totalResultados: number, totalPaginas: number, resultados: Array<T>){
    this.pagina = pagina
    this.itensPorPagina = itensPorPagina
    this.totalResultados = totalResultados
    this.totalPaginas = totalPaginas
    this.resultados = resultados
  }

  pagina: number = 0;
  itensPorPagina: number = 20;
  totalResultados: number = 0;
  totalPaginas: number = 0;

  resultados: Array<T> = [];
} 