import { IDeserializable } from "./deserializable";
import { Image } from "./image"

export type SectionDataInformation = {
    link?: string
    titulo?: string
    descricao?: string
    imagensArray?: Image[]
    arquivosArray?: any[]
} 

export class SectionData implements IDeserializable {

    subsections: any[] = []

    section_informations : ({
        order: number,
        section_data?: SectionDataInformation
    })[] = []


    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }
} 