import { IDeserializable } from './deserializable';

export class Category implements IDeserializable{
    id?: number;
    name?: string;
    quantity?: number;
    description?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
