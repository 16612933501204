import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"

import './modal-pesquisa.scss'
import { useModalPesquisaContext } from "../../contexts/modal-pesquisa.context"
import { createSearchParams, Link, useNavigate } from "react-router-dom"

export const ModalPesquisa = () => {
  const { show, setShow } = useModalPesquisaContext()

  const [value, setValue] = useState<string>('')

  // useEffect(() => setValue(value.replace(/\s/g, '')), [value])

  return (
    <Modal show={show} fullscreen={true} onHide={() => setShow(false)} className='modalPesquisa'>
      <Modal.Header closeButton>
        <Modal.Title>Modal</Modal.Title>
        <i className="bi bi-x-lg closeModal" onClick={() => setShow(false)}  ></i> 
        {/* <XLg className="closeModal" onClick={() => setShow(false)} /> */}
      </Modal.Header>
      <Modal.Body>
        <InputPesquisa value={value} setValue={setValue} setShow={setShow} />
      </Modal.Body>
    </Modal>
  )
}

const InputPesquisa = ({ value, setValue, setShow }: { value: string, setValue: (v: string) => void, setShow: (v: boolean) => void }) => {
  const [showAlert, setShowAlert] = useState(false)
  const navigate = useNavigate()

  useEffect(() => setShowAlert(false), [value])

  const search = () => {

    if(!value.length){
      setShowAlert(true)
    }else {
      var pesquisa = value
      setValue('')
      setShow(false)
      navigate({
        pathname: '/produtos/pesquisa',
        search: `?${createSearchParams([['search', pesquisa]])}`
      })
    }
  }

  return (
    <div className='boxPesquisa'>
      <div className='inputPesquisa'>
        <input type="text" value={value} onChange={(e) => setValue(e.target.value)} id='input-pesquisa' 
          placeholder="Digite o produto que procura" 
          spellCheck={false} 
          autoComplete='off'
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              search()
            }
          }}
        />
        <label htmlFor="input-pesquisa">
          <i className="bi bi-search iconSearch" onClick={() => search()}  ></i> 
          {/* <Search className="iconSearch" onClick={() => search()}/> */}
        </label>
      </div>
      <div className='inputAlert'>
        { showAlert && 
          <span>
            Digite algo para poder pesquisar, ou <Link to='/produtos/pesquisa' onClick={() => setShow(false)}>ver todos</Link>
          </span>
        }

      </div>
    </div>
  )
}