import React, { Suspense, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import './sessao-sobre-nos.scss'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../themes/colors';
export type SessaoSobreNosVariant = 'normal' | 'resumo'

const ReactPlayerLazy = React.lazy(() => import('react-player'));

export type SecaoSobreNosData = {
  titulo: string,
  paragrafosHtmlAsString: string[],
  link: string,
  video: {
    thumbnailSrc: string,
    videoSrc: string
  }
}

export const SessaoSobreNos = ({ variant, data }: { variant: SessaoSobreNosVariant, data: SecaoSobreNosData | undefined }) => {

  const [show, setShow] = useState(false);
  const [isResume, setIsResume] = useState(false)

  useEffect(() => setIsResume(variant === 'resumo'), [variant])


  return (data ?
    <section id='sessaoSobreNos' className={variant}>
      <div className='boxExterno'>
        <Container>
          <Row className='main'>
            <Col xs={12} lg={6} className='box1'>
              <div className='mediaBox' style={{ backgroundImage: `url(${data?.video.thumbnailSrc})` }}>
                <div className='boxButton' onClick={() => setShow(true)}>
                  <i className="bi bi-play-fill" style={{ color: "white", fontSize: '2rem' }} ></i>
                  {/* <PlayFill className='playButton' /> */}
                </div>
                <div id='pulsar'>

                </div>
              </div>
              <div className='test'>

              </div>
            </Col>
            <Col xs={12} lg={6} className='box2'>
              <div>
                {/* <div className='tag'></div> */}
                <div className='titulo'>{data?.titulo}</div>
                <div className='description'>
                  {data &&
                    data?.paragrafosHtmlAsString.map((paragrafo, i) =>
                      <div key={'p' + i} className='paragrafo'>
                        {parse(paragrafo)}
                      </div>
                    )
                  }
                </div>

                {isResume &&
                  <div className='saibaMais'>
                    {data &&
                      <Link to={data?.link ?? ""} className='saibaMaisButton' title="Sobre nós">
                        <span>saiba mais</span>
                        <i className="bi bi-arrow-right" style={{ color: "white", fontSize: '1.0rem', marginLeft: '5px' }} ></i>
                        {/* <ArrowRight className='arrowRight' /> */}
                      </Link>
                    }
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal className="sobreNosModal" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <i onClick={() => setShow(false)} className="bi bi-x-lg closeModal" style={{ fontSize: '2rem', marginRight: '25px' }} ></i>
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
            <ReactPlayerLazy url={data?.video.videoSrc} playing={true} controls={true} />
          </Suspense>
        </Modal.Body>
      </Modal>
    </section>
    :
    <section id='sessaoSobreNos'>
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <Container fluid className="containerLoading">
          <Row>
            <Col lg={6}>
              <Skeleton className="videoLoading" />
            </Col>
            <Col className="containerEscrita" lg={6}>
              <Skeleton className="tituloLoading" />
              <div className='containerTextLoading'>
                <Skeleton className="textoLoading" count={5} />
              </div>
              <div className='containerTextLoading'>
                <Skeleton className="textoLoading" count={5} />
              </div>

              {isResume ?
                <Skeleton className="botaoLoading" />
                :
                <div className='containerTextLoading'>
                  <Skeleton className="textoLoading" count={5} />
                </div>
              }
            </Col>
          </Row>
        </Container>
      </SkeletonTheme>
    </section>
  )
}