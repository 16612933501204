import { IDeserializable } from "./deserializable";

export type CategoriaType = {
  id?: number,
  title?: string,
  description?: string,
  image?: string,
  type?: string,
  quantity?: number
}

export default class CategoriasModel implements IDeserializable {

  dados?:Array<CategoriaType>
  erro?:boolean
  
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}