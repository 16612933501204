import React from 'react'
import { useEffect } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { useScreenContext } from '../../../contexts/screen.context'
import { gestorReqs } from '../../../servicos/gestor'
import { toUrlAmigavelConverter } from '../../../utilidades/funcoes-auxiliares'
import { ProdutoCard, ProdutoCardTipo } from '../../produto-card/produto-card'

import './sessao-produtos-relacionados.scss'


type Estado = {
  carregando: boolean,
  erro: boolean
}

const splitarProdutos = (qtdPorLinha: number, produtos: ProdutoCardTipo[]): ProdutoCardTipo[][] => {

  var produtosSplitados: ProdutoCardTipo[][] = []
  var coluna = 0

  while (1) {
    var array: ProdutoCardTipo[] = []

    for (let cont of Array(qtdPorLinha).fill(null)) {
      if (coluna >= produtos.length) break
      array.push(produtos[coluna])
      coluna += 1
    }

    produtosSplitados.push(array)

    if (coluna >= produtos.length) break
  }

  return produtosSplitados
}



export const SessaoProdutosRelacionados = () => {

  const { mobile } = useScreenContext()
  const { produtoId, subcategoriaId, nomeSubcategoria } = useParams()

  const [estado, setEstado] = React.useState<Estado>({ carregando: true, erro: false })
  const [produtos, setProdutos] = React.useState<ProdutoCardTipo[]>([])

  useEffect(() => {
    setEstado({carregando: true, erro: false})
    gestorReqs.getProdutosServicosPorCategoria(1, 12, Number(subcategoriaId)).then(response => {
      setEstado({carregando: true, erro: false})
      if (!response.error && response.dados) {
        var arr: ProdutoCardTipo[] = response.dados.resultados.filter(p => p.id != Number(produtoId)).map(ps => {
          return {
            id: ps.id,
            titulo: ps.title,
            descricao: ps.description,
            imagem: ps.imagensArray?.[0]?.src ?? '',
            link: `/produtos/${subcategoriaId}/${nomeSubcategoria}/${ps.id}/${toUrlAmigavelConverter(ps.title)}`
          }
        })

        setProdutos([...arr])
        setEstado({ carregando: false, erro: false })

      } else setEstado({ carregando: false, erro: true })
    })

  }, [produtoId, subcategoriaId])

  return <Container className='produtosRelacionados'>

    <Row>
      <Col xs={12}>
        { estado.carregando ?  <SkeletonTitulo /> : 
          <div className='titulo'>
            <span>Outros Produtos</span>
          </div>
        }
      </Col>
    </Row>
    { estado.carregando ? <SkeletonProdutosRelacionados /> :
      <Carousel indicators={true} controls={!mobile && produtos.length > 4} className='produtosRelacionadosCarousel'>
        {
          splitarProdutos((mobile ? 1 : 4), mobile ? produtos.slice(0, 6) : produtos).map((produtos, idx) => (
            <Carousel.Item key={idx}>
              <Row>
                {
                  produtos.map((produto, i) => (

                    <Col xs={12} lg={3} key={i}>
                      <ProdutoCard dados={produto} />
                    </Col>
                  ))
                }
              </Row>
            </Carousel.Item>
          ))
        }
      </Carousel>
    }
  </Container>

}


const SkeletonProdutosRelacionados = () => {
  const { mobile } = useScreenContext()

  return (
    <Row>
      {
        Array(mobile ? 1 : 4).fill(null).map((v, i) => (
          <Col xs={12} lg={3} className='skeletonProduto' key={i}>
            <Skeleton width={'100%'} height={'100%'} />
          </Col>
        ))
      }
    </Row>
  )
}

const SkeletonTitulo = () => {
  return (
    <div className='skeletonTitulo'>
      <div className='span'>
        <Skeleton width={'100%'} height={'100%'} />
      </div>
    </div>
  )
}