import * as React from 'react'

interface IProps {
  children: JSX.Element
}

interface IContext {
  show: boolean
  setShow: (show: boolean) => void
  toggleShow: () => void
}

const ModalPesquisaContext = React.createContext<IContext>({} as IContext)

export const ModalPesquisaProvider: React.FC<IProps> = ({children}) => {

  const [show, setShow] = React.useState<boolean>(false)

  const toggleShow = () => {
    setShow(!show)
  }

  const context = {
    show,
    setShow,
    toggleShow
  }

  return <ModalPesquisaContext.Provider value={context}>{children}</ModalPesquisaContext.Provider>
}

export const useModalPesquisaContext = () => {
  return React.useContext(ModalPesquisaContext)
}
