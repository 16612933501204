import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { MissaoDadosType, SessaoMissao } from '../../componentes/sessoes/sessao-missao/sessao-missao'
import { SessaoNossaHistoria, SessaoNossaHistoriaDataType } from '../../componentes/sessoes/sessao-nossa-historia/sessao-nossa-historia'
import { SecaoSobreNosData, SessaoSobreNos } from '../../componentes/sessoes/sessao-sobre-nos/sessao-sobre-nos'
import { gestorReqs } from '../../servicos/gestor'
import { useTitle } from '../../utilidades/tittle'
import './pagina-sobre.scss'

type SessaoNossaHistoriaDados = {
  titulo: string,
  dados: SessaoNossaHistoriaDataType[]
}

export const PaginaSobre = () => {
  const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])
  const [sobreNosData, setSobreNosData] = useState<SecaoSobreNosData | undefined>(undefined)
  const [missaoDados, setMissaoDados] = useState<MissaoDadosType[]>()
  const [nossaHistoriaDados, setNossaHistoriaDados] = useState<SessaoNossaHistoriaDados | null>()

  useTitle("Sobre Nós - Real Metais");

  React.useEffect(() => {

    gestorReqs.getQuemSomos().then(resposta => {

      if (resposta.error) {
        return;
      }

      setSobreNosData({
        titulo: resposta.dados?.title ?? "",
        link: "sobre-nos",
        paragrafosHtmlAsString: [resposta.dados?.text ?? ""],
        video: {
          thumbnailSrc: resposta.dados?.imagensArray?.at(0)?.src ?? "",
          videoSrc: resposta.dados?.youtube_link ?? ""
        }
      });

    });

  }, []);

  useEffect(() => {
    gestorReqs.getBannerInterno('sobre-nos').then(resposta => {
      setThumbnailsBanner([{
        img: resposta.dados?.at(0)?.imagensArray.at(0)?.src ?? "",
        title: 'sobre nós',
        breadcrumbs: [
          {
            name: 'home',
            url: '/'
          }, {
            name: 'sobre nós',
            url: '/sobre-nos'
          }],
      }]);
    })
    document.documentElement.scrollTop = 0;
  }, [])

  React.useEffect(() => {
    console.log(nossaHistoriaDados)
  }, [nossaHistoriaDados])

  React.useEffect(() => {

    gestorReqs.getSections('sobre-nos').then((response) => {

      console.log('res', response)
      var auxMissao: MissaoDadosType[] = response[0].section_informations.map((section: any) => {
        return {
          titulo: section.section_data ? (section.section_data.titulo ? section.section_data.titulo : '') : '',
          textoAsHtml: section.section_data ? (section.section_data.descricao ? section.section_data.descricao : '') : ''
        }
      })
      setMissaoDados([...auxMissao])

      if (response.length >= 1) {
        if (response.length >= 1) {
          var auxNossaHistoria: SessaoNossaHistoriaDataType[] = response[1].subsections[0].section_informations.map((section: any) => {
            return {
              ano: section.section_data.titulo,
              textoAsHtml: section.section_data.descricao,
              imgSrc: section.section_data.imagensArray[0].src
            }
          })

          setNossaHistoriaDados({
            titulo: response[1].section_informations![0]!.section_data!.titulo!,
            dados: [...auxNossaHistoria]
          })
        }
      } else setNossaHistoriaDados(null)

    })
  }, [])

  return (
    <section id='paginaSobre'>
      {/* <iframe
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDhRnwcpwUp3yUdvsZ2y-6pBfXspuDyYq4&q=-24.047237121688095,-52.3878588006833|-24.04725671704302,-52.386002712109644"
        height="450"
        frameBorder="0"
        style={{border:0}}
        allowFullScreen
      ></iframe> */}
      <SessaoHeader variant='transparent' acessoRapido />
      <Banner variant='interna' thumbnails={thumbnailsBanner} />
      <SessaoSobreNos variant='normal' data={sobreNosData} />
      {<SessaoMissao dados={missaoDados} />}
      {<SessaoNossaHistoria dados={nossaHistoriaDados?.dados ?? []} titulo={nossaHistoriaDados?.titulo ?? ""} notFound={nossaHistoriaDados == null}/>}
    </section>
  )
}