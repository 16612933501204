import React, { Suspense, useEffect, useState } from 'react'
import { Banner, ThumbnailBanner } from '../../componentes/banner/banner'
import { SessaoHeader } from '../../componentes/sessoes/sessao-header/sessao-header'
import { SecaoSobreNosData } from '../../componentes/sessoes/sessao-sobre-nos/sessao-sobre-nos'

import './pagina-home.scss'
import { gestorReqs } from '../../servicos/gestor'
import { allowScroll } from '../../utilidades/manipuladores-html'
import { useScreenContext } from '../../contexts/screen.context'
import ScrollToTop from '../../utilidades/to-top'
import { useTitle } from '../../utilidades/tittle'
import { useSubmenuContext } from '../../componentes/sessoes/sessao-header/sessao-header-context'

const RestoDoHeader = React.lazy(() => import ('./lazy-home'));

export const PaginaHome = () => {
  const [thumbnailsBanner, setThumbnailsBanner] = React.useState<ThumbnailBanner[]>([])
  const [sobreNosData, setSobreNosData] = useState<SecaoSobreNosData | undefined>(undefined)
  const [description, setDescription] = useState("");
  const { mobile, width } = useScreenContext()

  const { submenuAberto, setSubmenuAberto } = useSubmenuContext()

  useTitle("Home - Real Metais");

  React.useEffect(() => {

    setSubmenuAberto(false)

    gestorReqs.getQuemSomos().then(resposta => {

      if (resposta.error) {
        return;
      }

      setSobreNosData({
        titulo: resposta.dados?.title ?? "",
        link: "sobre-nos",
        paragrafosHtmlAsString: [resposta.dados?.text ?? ""],
        video: {
          thumbnailSrc: resposta.dados?.imagensArray?.at(0)?.src ?? "",
          videoSrc: resposta.dados?.youtube_link ?? ""
        }
      });

    });

  }, [])

  React.useEffect(() => {

    gestorReqs.getBanner().then(resposta => {
      setThumbnailsBanner([{ img: resposta.dados?.at(0)?.image ?? "" }])
      setDescription(resposta.dados?.at(0)?.description || "");
    })

  }, [])

  useEffect(() => {

    if (mobile) {
      allowScroll()
      return
    }
    // else removeScroll()

    var section = 0
    var lastChange = Date.now()

    const _onwheel = (event: any) => {
      // if (window.innerWidth < 991.98 || submenuAberto) return

      // var oldSection = section

      // if (Date.now() - lastChange > 600) {
      //   lastChange = Date.now() + 500

      //   if (event.deltaY < 0) {
      //     var newSection = oldSection - 1
      //     if (section != newSection && newSection >= 0) {
      //       section = newSection
      //       scrollView('ancora-home-' + section)
      //     }
      //   }
      //   else if (event.deltaY > 0) {
      //     var newSection = oldSection + 1
      //     if (section != newSection && newSection <= 7) {
      //       section = newSection
      //       scrollView('ancora-home-' + section)
      //     }
      //   }
      // }
    }

    window.addEventListener('wheel', _onwheel)

    return () => {
      allowScroll()
      window.removeEventListener('wheel', _onwheel)
    }
  }, [, mobile, width, submenuAberto])

  const [ render,setRender ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 2000);
  }, [])

  return (
    <section id='paginaHome'>
      <ScrollToTop />
      <SessaoHeader variant='transparent' acessoRapido />
      <div className='ancora-home' id='ancora-home-0'></div>
      <Banner variant='normal' thumbnails={thumbnailsBanner} description={description} />

      <Suspense  fallback={<h1></h1>}>
        {render && <RestoDoHeader sobreNosData={sobreNosData}  ></RestoDoHeader>}
      </Suspense>
      
      
      {/* <div className='ancora-home' id='ancora-home-1'></div>
      <SessaoListagemCategorias variant='resumo' />
      <div className='ancora-home' id='ancora-home-2'></div>
      <SessaoSobreNos variant='resumo' data={sobreNosData} />
      <div className='ancora-home' id='ancora-home-3'></div>
      <SessaoInspireSe />
      <div className='ancora-home' id='ancora-home-4'></div>
      <SessaoPropaganda />
      <div className='ancora-home' id='ancora-home-5'></div>
      <SessaoListagemBlogs variant='resumo' titulo='Blog' />
      <div className='ancora-home' id='ancora-home-6'></div>
      <SessaoCatalogo />
      <div className='ancora-home' id='ancora-home-7'></div> */}
    </section>
  )
}